import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _659f037f = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _3d3016e2 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _21bf4a66 = () => interopDefault(import('../pages/member/index.vue' /* webpackChunkName: "pages/member/index" */))
const _0745b898 = () => interopDefault(import('../pages/member/complete/index.vue' /* webpackChunkName: "pages/member/complete/index" */))
const _eb5e6c52 = () => interopDefault(import('../pages/member/confirm/index.vue' /* webpackChunkName: "pages/member/confirm/index" */))
const _80b28ed0 = () => interopDefault(import('../pages/mypage/contact/index.vue' /* webpackChunkName: "pages/mypage/contact/index" */))
const _762e9ff0 = () => interopDefault(import('../pages/mypage/contract/index.vue' /* webpackChunkName: "pages/mypage/contract/index" */))
const _59d83b18 = () => interopDefault(import('../pages/mypage/hospital/index.vue' /* webpackChunkName: "pages/mypage/hospital/index" */))
const _4edde230 = () => interopDefault(import('../pages/mypage/lovot/index.vue' /* webpackChunkName: "pages/mypage/lovot/index" */))
const _41184ef9 = () => interopDefault(import('../pages/mypage/myaccount/index.vue' /* webpackChunkName: "pages/mypage/myaccount/index" */))
const _fe2f941e = () => interopDefault(import('../pages/mypage/news/index.vue' /* webpackChunkName: "pages/mypage/news/index" */))
const _74b2a111 = () => interopDefault(import('../pages/mypage/official-supporters/index.vue' /* webpackChunkName: "pages/mypage/official-supporters/index" */))
const _623222a6 = () => interopDefault(import('../pages/mypage/contact/complete/index.vue' /* webpackChunkName: "pages/mypage/contact/complete/index" */))
const _a37033ee = () => interopDefault(import('../pages/mypage/contact/confirm/index.vue' /* webpackChunkName: "pages/mypage/contact/confirm/index" */))
const _b53aaa46 = () => interopDefault(import('../pages/mypage/contact/history/index.vue' /* webpackChunkName: "pages/mypage/contact/history/index" */))
const _06c3035c = () => interopDefault(import('../pages/mypage/contract/billing/index.vue' /* webpackChunkName: "pages/mypage/contract/billing/index" */))
const _6513525e = () => interopDefault(import('../pages/mypage/contract/billing_address/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/index" */))
const _757e441e = () => interopDefault(import('../pages/mypage/contract/creditcard/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/index" */))
const _75b01484 = () => interopDefault(import('../pages/mypage/hospital/detail/index.vue' /* webpackChunkName: "pages/mypage/hospital/detail/index" */))
const _7aefb8b5 = () => interopDefault(import('../pages/mypage/lovot/owners/index.vue' /* webpackChunkName: "pages/mypage/lovot/owners/index" */))
const _a4f2e3c4 = () => interopDefault(import('../pages/mypage/lovot/update/index.vue' /* webpackChunkName: "pages/mypage/lovot/update/index" */))
const _c69af948 = () => interopDefault(import('../pages/mypage/myaccount/change/index.vue' /* webpackChunkName: "pages/mypage/myaccount/change/index" */))
const _75b18b6a = () => interopDefault(import('../pages/mypage/myaccount/confirm/index.vue' /* webpackChunkName: "pages/mypage/myaccount/confirm/index" */))
const _45cba34e = () => interopDefault(import('../pages/mypage/contact/history/detail/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/index" */))
const _4e670ef8 = () => interopDefault(import('../pages/mypage/contract/billing_address/change/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/change/index" */))
const _8653897c = () => interopDefault(import('../pages/mypage/contract/billing_address/confirm/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/confirm/index" */))
const _1fdc18fa = () => interopDefault(import('../pages/mypage/contract/billing/detail/index.vue' /* webpackChunkName: "pages/mypage/contract/billing/detail/index" */))
const _202a6a97 = () => interopDefault(import('../pages/mypage/contract/creditcard/change/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/change/index" */))
const _61368b8f = () => interopDefault(import('../pages/mypage/contract/creditcard/confirm/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/confirm/index" */))
const _72309605 = () => interopDefault(import('../pages/mypage/contact/history/detail/complete/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/complete/index" */))
const _91e3ea6c = () => interopDefault(import('../pages/mypage/contact/history/detail/confirm/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/confirm/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _659f037f,
    name: "404"
  }, {
    path: "/error",
    component: _3d3016e2,
    name: "error"
  }, {
    path: "/member",
    component: _21bf4a66,
    name: "member"
  }, {
    path: "/member/complete",
    component: _0745b898,
    name: "member-complete"
  }, {
    path: "/member/confirm",
    component: _eb5e6c52,
    name: "member-confirm"
  }, {
    path: "/mypage/contact",
    component: _80b28ed0,
    name: "mypage-contact"
  }, {
    path: "/mypage/contract",
    component: _762e9ff0,
    name: "mypage-contract"
  }, {
    path: "/mypage/hospital",
    component: _59d83b18,
    name: "mypage-hospital"
  }, {
    path: "/mypage/lovot",
    component: _4edde230,
    name: "mypage-lovot"
  }, {
    path: "/mypage/myaccount",
    component: _41184ef9,
    name: "mypage-myaccount"
  }, {
    path: "/mypage/news",
    component: _fe2f941e,
    name: "mypage-news"
  }, {
    path: "/mypage/official-supporters",
    component: _74b2a111,
    name: "mypage-official-supporters"
  }, {
    path: "/mypage/contact/complete",
    component: _623222a6,
    name: "mypage-contact-complete"
  }, {
    path: "/mypage/contact/confirm",
    component: _a37033ee,
    name: "mypage-contact-confirm"
  }, {
    path: "/mypage/contact/history",
    component: _b53aaa46,
    name: "mypage-contact-history"
  }, {
    path: "/mypage/contract/billing",
    component: _06c3035c,
    name: "mypage-contract-billing"
  }, {
    path: "/mypage/contract/billing_address",
    component: _6513525e,
    name: "mypage-contract-billing_address"
  }, {
    path: "/mypage/contract/creditcard",
    component: _757e441e,
    name: "mypage-contract-creditcard"
  }, {
    path: "/mypage/hospital/detail",
    component: _75b01484,
    name: "mypage-hospital-detail"
  }, {
    path: "/mypage/lovot/owners",
    component: _7aefb8b5,
    name: "mypage-lovot-owners"
  }, {
    path: "/mypage/lovot/update",
    component: _a4f2e3c4,
    name: "mypage-lovot-update"
  }, {
    path: "/mypage/myaccount/change",
    component: _c69af948,
    name: "mypage-myaccount-change"
  }, {
    path: "/mypage/myaccount/confirm",
    component: _75b18b6a,
    name: "mypage-myaccount-confirm"
  }, {
    path: "/mypage/contact/history/detail",
    component: _45cba34e,
    name: "mypage-contact-history-detail"
  }, {
    path: "/mypage/contract/billing_address/change",
    component: _4e670ef8,
    name: "mypage-contract-billing_address-change"
  }, {
    path: "/mypage/contract/billing_address/confirm",
    component: _8653897c,
    name: "mypage-contract-billing_address-confirm"
  }, {
    path: "/mypage/contract/billing/detail",
    component: _1fdc18fa,
    name: "mypage-contract-billing-detail"
  }, {
    path: "/mypage/contract/creditcard/change",
    component: _202a6a97,
    name: "mypage-contract-creditcard-change"
  }, {
    path: "/mypage/contract/creditcard/confirm",
    component: _61368b8f,
    name: "mypage-contract-creditcard-confirm"
  }, {
    path: "/mypage/contact/history/detail/complete",
    component: _72309605,
    name: "mypage-contact-history-detail-complete"
  }, {
    path: "/mypage/contact/history/detail/confirm",
    component: _91e3ea6c,
    name: "mypage-contact-history-detail-confirm"
  }, {
    path: "*",
    component: _659f037f,
    name: "notfound"
  }, {
    path: "/",
    component: _4edde230,
    name: "lovot"
  }, {
    path: "/license",
    beforeEnter: (to, from, next) => {
          // Put the full page url including the protocol http(s) below
          window.location = 'https://lovot.life/terms/license/'
        }
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
